import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import ItemsContent from 'components/ItemsContent';
import { Status } from 'context/RestAPI';


function getApplicationObjects(apps, appName, objectsName){
	for (const i in apps.apps[appName].applicationObjects) {
		if (apps.apps[appName].applicationObjects[i].objectsName != objectsName) {
			continue;
		}
		return apps.apps[appName].applicationObjects[i];
	}
}

function AppPage({ pageContext, location, authData, accountId, applications }) {
	if (!applications || applications.applicationKeys.length == 0) {
		return (
			<Layout location={location} 
					title={pageContext.objectsName} 
					authData={authData} 
					applications={applications}
			>
				No Applications
			</Layout>
		);
	}
	const applicationObjects = getApplicationObjects(applications, pageContext.application, pageContext.objectsName);

	// When the data was not yet loaded
	if (applicationObjects.status != Status.Success) {
		return (
			<Layout location={location} 
					title={applicationObjects.objectsName} 
					authData={authData} 
					applications={applications}
			>
				{applicationObjects.status}
			</Layout>
		);
	}

	return (
		<Layout location={location} 
				title={applicationObjects.objectsName} 
				authData={authData} 
				applications={applications}
		>
			{(accountId) ? (
				<ItemsContent 
					accountId={accountId}
					authData={authData}
					applicationObjects={applicationObjects}
					applications={applications}
				/>
			) : null }
		</Layout>
	);
}
AppPage.propTypes = {
	location: PropTypes.object,
	authData: PropTypes.object.isRequired,
	accountId: PropTypes.string,
	applications: PropTypes.object
}
export default AppPage;
